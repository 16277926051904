<template>
  <div
    class="u-width-100 u-display-flex u-spacing-p-xxl budget-panner-dashboard"
    :class="!savePlan && 'u-position-relative'"
  >
    <div
      v-if="savePlan"
      class="u-display-flex loader-z-index fill--parent u-flex-align-items-center u-flex-justify-content-center u-flex-direction-column u-width-100"
    >
      <div class="u-font-size-4 u-font-style-italic">
        Saving your budget plan..
      </div>
      <div
        class="u-spacing-mt-m u-spacing-mb-s u-width-40"
        :class="savePlan ? 'loader' : ''"
      />
    </div>
    <section class="create-plan--steps u-width-100 u-position-relative">
      <budgetPlannerHeader
        :default-margin-bottom="false"
        :step="graphStep"
      />
      <div
        class="u-width-100 u-display-flex u-spacing-mv-l u-spacing-pt-m u-spacing-ph-m customBorder u-position-relative"
        :class="[
          getSeasonality.load &&
            'u-flex-align-items-center u-flex-justify-content-center'
        ]"
      >
        <div
          v-if="getSeasonality.load"
          class="u-display-flex fill--parent u-flex-align-items-center u-flex-justify-content-center u-flex-direction-column u-width-100"
        >
          <div class="u-font-size-4 u-font-style-italic">
            Looking at your historical data...
          </div>
          <div
            class="u-spacing-mt-m u-spacing-mb-s u-width-40"
            :class="getSeasonality.load ? 'loader' : ''"
          />
        </div>
        <div
          style="min-height: 332px"
          :class="
            getSeasonality.load &&
            'u-display-flex u-flex-align-items-center u-flex-justify-content-center'
          "
          class="u-width-100 u-spacing-mt-m u-margin-bottom-40"
        >
          <div class="u-display-flex u-width-100">
            <div class="u-display-flex u-width-100">
              <rb-button
                :click-fn="selectEntireBusinessScope"
                class="u-spacing-mr-m valueDropdown radius-curved-dropdown"
                size="s"
                text="Entire Business"
                :class="{
                  activeSelection: selectionType === 'EB'
                }"
              />

              <rb-select
                v-for="(level, key) in getSelectedLevels"
                :key="key"
                v-tippy="{ placement: 'top', arrow: true }"
                class-name="bp-select"
                :custom-trigger="true"
                :is-multi-select="true"
                :on-close="
                  (c, data) =>
                    handleChange(data, level.dimensionLabel, level.levelName)
                "
                :class="{
                  activeSelection:
                    selectedLevelText && selectedLevelText[level.dimensionLabel]
                }"
                class="u-spacing-mr-m valueDropdown u-max-width-100 radius-curved-dropdown"
                :send-details="true"
                filter
                level
                :options="
                  ((levelEntities && levelEntities[level.dimensionLabel]) || [])
                    .length
                    ? levelEntities[level.dimensionLabel]
                    : [{ title: 'No data', value: '', disabled: true }]
                "
                @change="
                  (data) =>
                    handleChange(data, level.dimensionLabel, level.levelName)
                "
              >
                <div
                  slot="trigger"
                  :key="
                    selectedLevelText && selectedLevelText[level.dimensionLabel]
                  "
                  class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
                >
                  <div
                    v-tippy
                    class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-min-width-100px u-spacing-pl-xs"
                    style="max-width: 200px"
                    :title="
                      selectedLevelText &&
                      selectedLevelText[level.dimensionLabel]
                    "
                    :class="
                      selectedLevelText &&
                      selectedLevelText[level.dimensionLabel]
                        ? 'u-color-blue-base'
                        : 'u-color-grey-lighter'
                    "
                  >
                    <span
                      v-if="
                        selectedLevelText &&
                        selectedLevelText[level.dimensionLabel]
                      "
                    >
                      {{ selectedLevelText[level.dimensionLabel] }}
                    </span>
                    <span v-else> {{ level.dimensionLabel }} </span>
                  </div>
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-spacing-mh-xs"
                    :class="
                      selectionType === level.dimensionLabel
                        ? 'u-color-blue-base'
                        : 'u-color-grey-lighter'
                    "
                    icon="caret-down"
                  />
                </div>
                <div
                  v-if="
                    (
                      (levelEntities && levelEntities[level.dimensionLabel]) ||
                      []
                    ).length
                  "
                  slot="footer"
                  class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s u-border-top u-border-width-s u-border-color-grey-xxx-light"
                >
                  <rb-button
                    :click-fn="clickOutside.bind(this)"
                    :type="'filled'"
                    :disabled="disableApply"
                    text="Apply"
                    size="s"
                  />
                </div>
              </rb-select>
              <div class="dummy-element">&nbsp;</div>
            </div>
            <div
              class="u-width-100 u-flex-justify-content-flex-end u-display-flex u-flex-align-items-center"
            >
              <splitTabs
                tab1="Cumulative"
                tab2="Absolute"
                class="split-tabs-container"
                @tabClicked="onTabClick"
              />
              <div class="u-spacing-ml-m">
                <rb-select
                  class="fs-budget-plan-dashboard-roll-up u-spacing-mr-m valueDropdown u-max-width-100"
                  :on-close="getChartDataWithRollUp"
                  :send-details="true"
                  :options="rollUps"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
                  >
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      icon="calendar"
                    />
                    <span
                      class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
                      >Roll up by:&nbsp;</span
                    >
                    <div
                      class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-light"
                    >
                      {{ selectedRollUp.name }}
                    </div>
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      icon="caret-down"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      {{ option.name }}
                    </div>
                  </template>
                </rb-select>
              </div>
            </div>
          </div>
          <div
            v-if="
              !getSelectedLevels.load &&
              getPacingChartData &&
              getPacingChartData[selectedTab] &&
              getPacingChartData[selectedTab].length &&
              getPacingChartData[selectedTab][0].length > 1
            "
            class="u-spacing-mt-m u-spacing-mb-s u-width-100 u-display-flex"
          >
            <chart
              :config="config.widgets['widget1'].body.chart"
              :data="getPacingChartData[selectedTab]"
            />
          </div>
          <div
            v-else
            class="u-font-size-4 u-font-style-italic no-data"
          >
            No data available.
          </div>
        </div>
      </div>
      <div :key="seasonalityEventsKey">
        <budgetPlannerHeader :step="getSubStep" />
        <div
          v-for="(event, index) in seasonalityEvents"
          :key="event.id + index"
          class="seasonality-event-row"
          :class="{
            'is-inactive': getSeasonality.load || isCurrentEventSaved(event.id)
          }"
        >
          <addSeasonalityEvent
            :config="event"
            :current-index="index"
            :show-header="index === 0"
            :scope-items="scopeItemsForSeasonality[event.id]"
            :is-chart-loading="getSeasonality.load"
            :remaining-budget="
              getSeasonality &&
              getSeasonality.data &&
              getSeasonality.data.remainingBudget
            "
            :validation-errors="
              (getSeasonality && getSeasonality.errorMessages) || []
            "
            @updateEvent="onEventUpdate"
            @deleteSavedEvent="onDeleteSavedEvent"
            @saveEvent="onSaveEvent"
            @deleteEvent="onDeleteEvent"
          />
        </div>
        <div>
          <span
            class="u-cursor-pointer u-color-blue-base u-font-size-5 u-display-inline-flex u-flex-align-items-center fs-budget-plan-add-event"
            :class="{ 'is-disabled': getSeasonality.load || disableAddEvent }"
            @click.stop="addEvent"
          >
            <rb-icon
              class="rb-icon--small u-spacing-mr-xs"
              icon="add-circle-fill"
            />
            <span>Add event</span>
          </span>
        </div>
      </div>
      <div class="u-display-flex u-flex-align-items-center">
        <budgetPlannerFooter
          :disable-right-btn="disableRightButton || !refreshedWeightStatus"
          :btn-right-text="true ? 'Update plan' : 'Create plan'"
          class="u-spacing-pt-xxl u-spacing-pb-xl"
          custom-class-left="fs-budget-plan-seasonality-back"
          custom-class-right="fs-budget-plan-seasonality-next"
          @onClickBtnRight="nextStep"
          @onClickBtnLeft="goBack"
        />
        <div
          v-if="!refreshedWeightStatus"
          class="u-display-flex u-font-size-5 u-color-blue-base u-spacing-pt-l u-spacing-pl-l u-flex-align-items-center"
        >
          <div class="u-spacing-pr-s">
            Please hold on for a moment while we update your budget plan. <br />
            This should only take a few seconds. Thank you for your patience!
          </div>
          <loader
            :loading="true"
            color="#007cf6"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import budgetPlannerHeader from '@/components/ams/budgetPlanner/steps/common/header';
import budgetPlannerFooter from '@/components/ams/budgetPlanner/steps/common/footer';
import addSeasonalityEvent from '@/pages/budget-planner-v2/create-plan/steps/seasonalityStep/addEvent.vue';
import moment from 'moment-timezone';
import chart from '@/components/basic/chart';
import splitTabs from '@/components/widgets/splitTabs';
import widgetsConfig from '@/pages/budget-planner-v2/create-plan/steps/seasonalityStep/widgets.js';
import { cloneDeep, groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import loader from '@/components/basic/loader';

import { retailerFiltersMap } from '@/pages/budget-planner-v2/dashboard/retailerConfig.js';
import Vue from 'vue';
import utils from '@/utils/helpers';

export default {
  components: {
    splitTabs,
    budgetPlannerHeader,
    budgetPlannerFooter,
    addSeasonalityEvent,
    chart,
    loader
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      isNextYearPlan: this.$route.query?.nextYearPlan,
      seasonalityEventsKey: 1,
      delimiter: '|-|',
      where: [],
      validMap: {},
      firstLoad: false,
      rollUps: [
        { name: 'Days', value: 'timeSeriesDays' },
        { name: 'Weeks', value: 'timeSeriesWeeks' },
        { name: 'Months', value: 'timeSeriesMonths' }
      ],
      disableApply: true,
      selectedRollUp: { name: 'Days', value: 'timeSeriesDays' },
      config: {},
      applyClicked: false,
      savePlan: false,
      levelEntities: {},
      levelSelected: {},
      levelOptionsDropdown: [],
      seasonalityEvents: [],
      scopeItemsForSeasonality: {},
      scopeItems: [],
      selectedTab: 'cumulative',
      selectionType: 'EB',
      selectedLevelText: {},
      currentFocusedEvent: ''
    };
  },
  computed: {
    refreshedWeightStatus() {
      return this.$store.getters['budgetPlannerOmni/getWeightsRefreshedStatus'];
    },
    isAutomationsEnabled() {
      const automationsConfigCheck = Vue.options.filters.config_check(
        'feature.pages.boV2_automations'
      )?.enable;
      const internalUserCheck = utils.internalUserCheck(window.user);
      return automationsConfigCheck && internalUserCheck;
    },
    disableAddEvent() {
      return (
        (this.seasonalityEvents?.length > 0 && this.currentFocusedEvent) ||
        false
      );
    },
    disableRightButton() {
      let disable = false;
      for (const key in this.validMap) {
        if (!this.validMap[key]) {
          disable = true;
        }
      }
      return this.getSeasonality.load || this.getSeasonality.error || disable;
    },
    retailer() {
      return this.$store.getters.getRetailer;
    },
    getSeasonalityEntityMap() {
      return this.$store.getters['budgetPlannerOmni/getSeasonalityEntityMap'];
    },
    getPacingChartData() {
      const timeseries =
        this.getSeasonality?.data?.[this?.selectedRollUp?.value] || [];
      const feedDateArr = ['feed_date'];
      const cumulativeChartDataArr = ['Budget'];
      const absoluteChartDataArr = ['Budget'];
      timeseries.forEach((element) => {
        feedDateArr.push(element.feed_date);
        cumulativeChartDataArr.push(element.plannedBudget);
        absoluteChartDataArr.push(element.absoluteBudget);
      });
      return {
        cumulative: [feedDateArr, cumulativeChartDataArr],
        absolute: [feedDateArr, absoluteChartDataArr]
      };
    },
    getSeasonality() {
      return this.$store.getters['budgetPlannerOmni/getSeasonality'];
    },
    getPacingPayload() {
      return this.$store.getters['budgetPlannerOmni/getPacingPayload'];
    },
    getSubStep() {
      return {
        header: {
          title:
            'Would you like to include any specific events to adjust your budget plan?',
          desc: [
            'Add seasonality events such as Black Friday, Prime Day etc. at entire business, profile or portfolio level for specific time period.'
          ]
        }
      };
    },
    getSelectedLevels() {
      const selectedLelves = cloneDeep(
        this.$store.getters['budgetPlannerOmni/getSelectedLevels']
      );
      const levels = [];
      for (const level in selectedLelves) {
        if (selectedLelves[level]) {
          selectedLelves[level].levelName = level;
          levels.push(selectedLelves[level]);
        }
      }
      return levels;
    },
    graphStep() {
      const thisYear = new Date().getFullYear();
      const year = this.$route?.query?.nextYearPlan ? thisYear + 1 : thisYear;
      this.step.header.title = 'Cumulative budget plan for the year ' + year;
      this.step.breadCrumbTitle =
        'Cumulative budget plan for the year ' + year + '.';
      return this.step;
    }
  },
  watch: {
    getSeasonality: {
      handler(newVal) {
        if (!newVal.load) {
          if (newVal.error && !newVal?.errorMessages?.length) {
            this.$snackbar.open({
              message:
                'Something went wrong with the preview API, try again later',
              duration: 6000,
              actionText: ''
            });
          }
          this.setSeasonalityEvents();
        }
      },
      deep: true
    }
  },
  created() {
    this.config = widgetsConfig.config(this);
    const entityMap = {};
    this.createEntityMap(this.getPacingPayload, entityMap);
    this.scopeItems = this.createLevelOptionsDropdown(
      this.getPacingPayload,
      entityMap
    );
    this.fetchPacingChart();
  },
  methods: {
    callPacingApi() {
      this.$store.dispatch('budgetPlannerOmni/formPacingPayload', {
        isNextYearPlan: !!this.isNextYearPlan,
        isAutomationsEnabled: this.isAutomationsEnabled
      });
    },
    isCurrentEventSaved(eventId) {
      // disable other events while editing one
      return (
        this.currentFocusedEvent?.length &&
        !this.currentFocusedEvent.includes(eventId)
      );
    },
    getSelectedLevelText() {
      const textMap = {};
      for (let key in this.levelSelected) {
        if (!textMap[key]) {
          textMap[key] = `${key}`;
        }
        const levelValues = this.levelSelected?.[key];
        if (levelValues?.length > 1) {
          textMap[key] = `${textMap[key]}'s > `;
        } else {
          textMap[key] = `${textMap[key]} > `;
        }
        for (let i = 0; i < levelValues?.length; i++) {
          textMap[key] = `${textMap[key]} ${levelValues?.[i]?.title}`;
          if (i !== levelValues?.length - 1) {
            textMap[key] = `${textMap[key]}, `;
          }
        }
      }
      return textMap;
    },
    formSelectedScope(values, row) {
      const [entity, id] = values.split('--|--');
      let selectedParentItem = {};
      let selectedChildItems = [];
      for (const item of this.scopeItems) {
        for (const nextLevel of item?.nextLevel || []) {
          if (nextLevel.value === entity) {
            selectedParentItem = item;
            selectedChildItems = [nextLevel];
            return {
              selectedChildItems,
              selectedParentItem,
              id
            };
          }
        }
      }
      return {
        selectedParentItem: null,
        selectedChildItems: null,
        id: null
      };
    },
    setSeasonalityEvents() {
      const tempSeasonalityEvents = [];
      const seasonalityEvents = [];
      for (const key in this.getSeasonalityEntityMap) {
        for (const row of this.getSeasonalityEntityMap[key]) {
          const { selectedParentItem, selectedChildItems, id } =
            this.formSelectedScope(key, row);
          if (selectedParentItem && selectedChildItems && id) {
            this.createScopeItemsForSeasonality(id);
            tempSeasonalityEvents.push({
              type: 'SEASONALITY',
              id,
              startDate: moment(row.startDate).format(),
              endDate: moment(row.endDate).format(),
              name: row.eventName,
              budget: parseInt(row.value),
              budgetType: row?.type?.toLowerCase(),
              selectedParentItem,
              selectedChildItems,
              isSaved: true,
              isValid: true,
              newEvent: false
            });
            this.validMap[id] = true;
          }
        }
      }

      const groupedSelections = groupBy(tempSeasonalityEvents, 'id');
      let tempRow = null;
      for (const groupKey in groupedSelections) {
        tempRow = null;
        for (const selection of groupedSelections[groupKey]) {
          if (!tempRow) {
            tempRow = selection;
          } else {
            tempRow.selectedChildItems = [
              ...tempRow.selectedChildItems,
              ...selection.selectedChildItems
            ];
          }
        }
        seasonalityEvents.push(tempRow);
      }
      this.seasonalityEvents = seasonalityEvents;
      this.seasonalityEventsKey++;
    },
    createScopeItemsForSeasonality(id) {
      this.scopeItemsForSeasonality[id] = this.scopeItems.map((item) => {
        return {
          ...item,
          selected: false,
          nextLevel: item.nextLevel.map((val) => ({ ...val, selected: false }))
        };
      });
    },
    handleChange(data, dimensionLabel, levelName) {
      if (data.length) {
        this.disableApply = false;
      } else {
        this.disableApply = true;
      }
      if (!data.length || !this.applyClicked) {
        return;
      }
      this.selectionType = dimensionLabel;
      this.levelSelected[dimensionLabel] = cloneDeep(data);
      this.selectedLevelText = this.getSelectedLevelText();
      this.applyClicked = false;
      this.disableApply = false;

      // remove other level data
      const otherLevelKey = Object.keys(this.levelEntities)?.filter?.(
        (val) => val !== dimensionLabel
      )?.[0];
      if (otherLevelKey) {
        this.levelEntities[otherLevelKey].forEach(
          (val) => (val.selected = false)
        );
        this.selectedLevelText[otherLevelKey] = '';
      }
      // sending level 1 info for level 2 selection too in pacing overview API
      this.where = data
        .map((item) => {
          const dimensionValue = item.value.split(this.delimiter)[0];
          const filter = {
            dimensionName: levelName,
            dimensionValue
          };
          return item.value.includes(this.delimiter)
            ? [
                filter,
                {
                  dimensionName: 'level1',
                  dimensionValue: item.value.split(this.delimiter)[1],
                  forLevel2: true
                }
              ]
            : [filter];
        })
        .flat();

      this.fetchPacingChart();
    },
    clickOutside() {
      this.applyClicked = true;
      this.disableApply = true;
      document.querySelector('.dummy-element').click();
    },
    getChartDataWithRollUp(context, selection) {
      if (selection.length === 0) {
        return;
      }
      this.selectedRollUp = {
        name: selection[0].name,
        value: selection[0].value
      };
    },
    onTabClick(data) {
      this.selectedTab = data.toLowerCase();
    },
    fetchPacingChart() {
      this.$store.dispatch('budgetPlannerOmni/getSeasonalityResponse', {
        where: this.where,
        addYear: !!this.isNextYearPlan
      });
    },
    createLevelOptionsDropdown(payload, entityMap) {
      const baseOptions = [];
      for (let row of this.getSelectedLevels) {
        if (entityMap?.[row?.dimensionLabel]?.length) {
          baseOptions.push({
            title: row.dimensionLabel,
            value: row.dimensionName,
            nextLevel: entityMap[row.dimensionLabel],
            hasParent: false,
            parentTitle: null
          });
        }
      }
      for (let key in entityMap) {
        entityMap[key].forEach((item) => {
          const parent = item?.value?.split(this.delimiter)?.[1];
          if (parent) {
            const title = item.value.split(this.delimiter)[0];
            item.title = `${title === 'null' ? 'Rest' : title} (${parent})`;
          }
        });
      }
      this.levelEntities = entityMap;
      return baseOptions;
    },
    createEntityMap(payload, entityMap) {
      const levels = payload.nextLevel;
      for (const row of levels) {
        if (!entityMap[row.levelName]) {
          entityMap[row.levelName] = [];
        }
        if (row.executionStrategy.length) {
          if (row.isUserDefined) {
            entityMap[row.levelName].push({
              title: row.ui_label,
              value: row.name,
              nextLevel: null,
              hasParent: true,
              parentTitle: row.levelName,
              parentValue: row.dimensionName,
              executionStrategy: row.executionStrategy
            });
          }
          this.createEntityMap(row, entityMap);
        }
      }
    },
    addEvent() {
      const validDate = this.isNextYearPlan
        ? moment().startOf('year').add(1, 'years').format()
        : moment().format();
      const id = uuidv4();
      this.createScopeItemsForSeasonality(id);
      this.validMap[id] = false;
      this.currentFocusedEvent = id;
      this.seasonalityEvents.push({
        type: 'SEASONALITY',
        id,
        startDate: validDate,
        endDate: validDate,
        name: null,
        budget: 0,
        budgetType: 'percentage',
        isSaved: false,
        isValid: false,
        newEvent: true
      });
    },
    selectEntireBusinessScope() {
      this.selectionType = 'EB';
      this.where = [
        {
          dimensionName: 'ENTIRE_BUSINESS',
          dimensionValue: 'ENTIRE_BUSINESS'
        }
      ];
      Object.values(this.levelEntities).forEach((val) =>
        val.forEach((item) => (item.selected = false))
      );
      Object.keys(this.selectedLevelText).forEach(
        (key) => (this.selectedLevelText[key] = '')
      );
      this.fetchPacingChart();
    },
    onFilterLevel() {},
    onEventUpdate(event) {
      this.validateEvent(event);
    },
    validateEvent(event) {
      this.validMap[event.id] = event.isValid;
      event.isSaved = false;
      this.currentFocusedEvent = event.id;
    },
    onDeleteSavedEvent(event) {
      this.currentFocusedEvent = '';
      this.onDeleteEvent(event);
      const existingEventMap = this.removeExistingSavedEvent(event);
      this.$store.commit(
        'budgetPlannerOmni/SAVE_SEASONALITY_EVENT_MAP',
        existingEventMap
      );
      this.callPacingApi();
      this.fetchPacingChart();
    },
    goBack() {
      this.$emit('prevStep', {});
    },
    async nextStep() {
      this.savePlan = true;
      try {
        await this.$store.dispatch('budgetPlannerOmni/saveBudgetPlan', {
          isNextYearPlan: !!this.isNextYearPlan,
          isAutomationsEnabledCheck: this.isAutomationsEnabled
        });
        localStorage.removeItem(retailerFiltersMap[this.retailer]);
        localStorage.removeItem(`${retailerFiltersMap[this.retailer]}-order`);
        this.$router.push({ name: 'budget-planner-dashboard-v2' });
        this.$store.commit(
          'budgetPlannerOmni/RESET_COMPLETE_BUDGET_PLAN_STATE'
        );
      } catch {
        this.$snackbar.open({
          message: 'Something went wrong try again later',
          duration: 6000,
          actionText: ''
        });
      } finally {
        this.savePlan = false;
      }
    },
    removeExistingSavedEvent(event) {
      const existingEventMap = cloneDeep(this.getSeasonalityEntityMap);
      for (const key in this.getSeasonalityEntityMap) {
        if (key.includes(event.id)) {
          delete existingEventMap[key];
        }
      }
      return existingEventMap;
    },
    onSaveEvent(event) {
      const entityBudgetMap = {};
      for (let item of event?.scope || []) {
        if (!entityBudgetMap[`${item}--|--${event?.id}`]) {
          entityBudgetMap[`${item}--|--${event?.id}`] = [];
        }
        entityBudgetMap[`${item}--|--${event?.id}`].push({
          type: event?.budgetType.toUpperCase(),
          value: event.budget,
          startDate: moment(event.startDate).format('YYYY-MM-DD'),
          endDate: moment(event.endDate).format('YYYY-MM-DD'),
          eventName: event.name,
          id: event.id
        });
      }
      const existingEventMap = this.removeExistingSavedEvent(event);
      this.$store.commit('budgetPlannerOmni/SAVE_SEASONALITY_EVENT_MAP', {
        ...existingEventMap,
        ...entityBudgetMap
      });
      event.isSaved = true;
      this.validMap[event.id] = true;
      this.currentFocusedEvent = '';
      this.callPacingApi();
      this.fetchPacingChart();
    },
    onDeleteEvent(event) {
      this.currentFocusedEvent = '';
      this.seasonalityEvents = this.seasonalityEvents.filter(
        (item) => item.id !== event.id
      );
    }
  }
};
</script>

<style lang="css" scoped>
.loader-z-index {
  z-index: 103;
}
.create-plan--steps:not(:first-child) {
  margin-top: 100px;
}
.step-workspace {
  padding-top: 40px;
}
.radius-curved-dropdown {
  border-radius: 39px !important;
}
.valueDropdown {
  border-radius: 13.5px;
  border: solid 1px #e9eaeb;
}
.valueDropdown:hover,
.valueDropdown:focus {
  border: 1px solid #007cf6 !important;
}
.valueDropdown:hover *,
.valueDropdown:focus * {
  fill: #007cf6 !important;
  color: #007cf6 !important;
}
.customBorder {
  border-radius: 4px;
  border: solid 1px #e9eaeb;
}
.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.activeSelection {
  border: 1px solid #007cf6 !important;
  color: #007cf6 !important;
}
.loader {
  height: 8px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #007bf63b;
  border-radius: 100px;
}
.loader:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 8px;
  background-color: #007cf6;
  animation: loading 12s linear;
}
@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.no-data {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
