import moment from 'moment-timezone';
export function dateRangeOutsideOneMonth(startDate, endDate) {
  // making sure date range is in same month
  const start = moment(startDate).format('M');
  const end = moment(endDate).format('M');
  return start !== end;
}
export function endDateLessThanStartDate(startDate, endDate) {
  const start = moment(startDate).format('YYYY-MM-DD');
  const end = moment(endDate).format('YYYY-MM-DD');
  return end < start;
}
