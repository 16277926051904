export default {
  config: (that) => {
    return {
      widgets: {
        widget1: {
          meta: {
            type: 'chart'
          },
          body: {
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%Y-%m-%d',
                timeseries: 'feed_date',
                point_format: {
                  Budget: () => {
                    if (that?.selectedRollUp?.name === 'Days') {
                      return 0;
                    } else {
                      return 3;
                    }
                  }
                },
                tooltip_format: {
                  pre: 'currency'
                },
                axes: {
                  Budget: 'y'
                },
                axis_format: {
                  y: {
                    pre: 'currency'
                  }
                },
                xDataKey: 'x',
                grid: 'xy',
                size: {
                  height: 332
                },
                line: {
                  connectNull: false
                }
              }
            }
          }
        }
      }
    };
  }
};
