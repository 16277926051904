<template>
  <div class="u-display-flex u-flex-direction-column u-spacing-mb-m">
    <div class="u-display-flex u-flex-align-items-flex-start">
      <!-- NAME -->
      <div class="u-spacing-pr-xl">
        <div
          v-if="showHeader"
          class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
        >
          Event name:
        </div>
        <div>
          <rb-input
            v-model="localEventData.name"
            class="u-width-240px u-display-flex u-flex-align-items-center seasonality-event-form-element"
            @input="eventLabelChanged"
          />
        </div>
      </div>
      <!-- Dates -->
      <div class="u-spacing-pr-xl u-display-flex u-flex-direction-column">
        <div class="timePeriod_wrp u-display-flex">
          <!-- Event start date -->
          <div class="u-spacing-pr-l">
            <div
              v-if="showHeader"
              class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
            >
              Start date:
            </div>
            <div
              v-click-outside="hideStartCalendar"
              class="seasonality-event-form-element"
            >
              <span
                class="filter--token u-font-size-6 u-border-radius-xs u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
                @click="openCalendar('startDate')"
              >
                <rb-icon
                  :icon="'calendar'"
                  class="rb-icon--small u-spacing-mr-xs"
                />
                <span class="u-spacing-ph-xs u-line-height-1_2">
                  {{ dateFormatFilter(localEventData.startDate) }}</span
                >
              </span>
              <datepicker
                ref="startDate"
                v-model="localEventData.startDate"
                name="startDate"
                input-class="hide_calendar_strategy"
                :disabled-dates="disabledDates"
              />
            </div>
          </div>

          <!-- Event end date -->
          <div>
            <div
              v-if="showHeader"
              class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
            >
              End date:
            </div>
            <div
              v-click-outside="hideEndCalendar"
              class="seasonality-event-form-element"
            >
              <span
                class="filter--token u-font-size-6 u-border-radius-xs u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
                @click="openCalendar('endDate')"
              >
                <rb-icon
                  :icon="'calendar'"
                  class="rb-icon--small u-spacing-mr-xs"
                />
                <span class="u-spacing-ph-xs u-line-height-1_2">
                  {{ dateFormatFilter(localEventData.endDate) }}</span
                >
              </span>
              <datepicker
                ref="endDate"
                v-model="localEventData.endDate"
                name="endDate"
                input-class="hide_calendar_strategy"
                :disabled-dates="disabledDates"
              />
            </div>
          </div>
        </div>
        <div
          v-if="dateErrorMsgs.length"
          class="u-display-flex u-spacing-pt-s"
        >
          <rb-icon
            class="u-flex-0 rb-icon--small u-color-red-base u-spacing-mr-xs u-flex-align-self-flex-start"
            icon="error-fill"
          />
          <div class="u-display-flex u-flex-direction-column">
            <div
              v-for="(dateError, index) in dateErrorMsgs"
              :key="dateError"
              class="u-font-size-5 u-color-red-base"
            >
              {{ dateError
              }}<span
                v-if="
                  dateErrorMsgs.length > 1 && index < dateErrorMsgs.length - 1
                "
                >,</span
              >
            </div>
          </div>
        </div>
      </div>
      <!--SCOPE-->
      <div class="u-spacing-pr-xl">
        <div
          v-if="showHeader"
          class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
        >
          Scope:
        </div>

        <rb-select
          v-tippy="{ placement: 'top', arrow: true }"
          :title="scopeDropDownDisplayText"
          class="valueDropdown seasonality-event-form-element"
          style="width: 140px"
          :send-details="true"
          :on-close="onCloseParentScope"
          :options="scopeItems"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
            style="height: 34px"
          >
            <div
              class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-color-grey-mid-light"
            >
              {{ scopeDropDownDisplayText }}
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-mid-light"
              icon="caret-down"
            />
          </div>
        </rb-select>
        <rb-select
          :key="childScopeKey"
          :class-name="'scope-option-selector seasonality-event-form-element'"
          position="is-bottom-left"
          place-holder="Search"
          :is-multi-select="true"
          :on-close="onCloseChildScope"
          :send-details="true"
          :options="computedSelectedChildOptions"
        >
          <div
            :ref="localEventData.id + '-scope'"
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
            style="display: none"
          />
        </rb-select>
      </div>
      <!-- BUDGET -->
      <div
        class="event-info-wrapper u-spacing-pr-xl u-flex-1 u-flex-shrink-past-min-size"
      >
        <div
          v-if="showHeader"
          class="u-font-size-5 u-spacing-pb-m u-font-weight-600"
        >
          Budget:
        </div>
        <div class="u-display-flex">
          <div class="u-position-relative">
            <rb-input
              v-model="localEventData.budget"
              class="u-width-100px u-display-flex u-flex-align-items-center seasonality-event-form-element"
              @input="budgetChanged"
            />
            <div
              v-if="localEventData.budgetType === 'percentage'"
              style="position: absolute; right: 8px; top: 12px"
              class="u-font-size-5"
            >
              %
            </div>
            <div
              v-if="localEventData.budgetType === 'absolute'"
              style="position: absolute; left: 8px; top: 12px"
              class="u-font-size-5"
            >
              {{ $currency }}
            </div>
          </div>

          <div
            class="valueDropdown u-display-flex u-flex-1 u-flex-shrink-past-min-size u-flex-align-items-center u-spacing-ml-s u-spacing-p-s"
          >
            <span
              v-tippy="{ placement: 'top', arrow: true, offset: '0, 10' }"
              :title="entityBudget.text"
              class="u-flex-1 u-text-overflow-ellipsis u-font-size-5 u-color-grey-mid-light"
              >{{ entityBudget.text }}</span
            >
          </div>
        </div>
        <div
          class="u-display-flex u-flex-1 u-flex-justify-content-space-between"
        >
          <div class="u-font-size-5 u-spacing-mt-s u-color-grey-mid-light">
            <span
              class="u-cursor-pointer"
              :class="{
                'u-color-blue-base': localEventData.budgetType === 'percentage'
              }"
              @click="budgetTypeChanged('percentage')"
              >Percent</span
            >
            <span> | </span>
            <span
              class="u-cursor-pointer"
              :class="{
                'u-color-blue-base': localEventData.budgetType === 'absolute'
              }"
              @click="budgetTypeChanged('absolute')"
              >Absolute</span
            >
          </div>

          <div
            v-if="
              eventBudgetInfo.remaining &&
              eventBudgetInfo.remainingBudgetPercentage >= 0 &&
              eventBudgetInfo.show
            "
            class="u-text-align-right u-spacing-mt-s u-font-size-5"
            :class="{ 'u-color-red-base': eventBudgetInfo.remaining < 0 }"
          >
            <span
              >{{ $currency }}{{ eventBudgetInfo.remaining }} ({{
                eventBudgetInfo.remainingBudgetPercentage
              }}%)</span
            ><span> left</span>
          </div>
        </div>
      </div>
      <!-- ACTION -->
      <div class="event-actions u-display-flex seasonality-event-form-element">
        <div
          v-show="!localEventData.isSaved"
          :class="{ 'disabled u-pointer-events-none': !localEventData.isValid }"
          class="u-cursor-pointer u-display-flex u-spacing-mr-m"
          @click="saveEvent"
        >
          <span
            class="u-bg-color-blue-base u-color-grey-white u-font-size-7 u-spacing-pv-xs u-spacing-ph-m u-border-radius-s u-font-weight-600"
            :style="{
              'margin-top': currentIndex == 0 ? '40px' : '10px',
              height: '19px'
            }"
            >Save</span
          >
        </div>
        <div
          v-show="!localEventData.isSaved"
          class="u-cursor-pointer u-spacing-mr-s"
          @click="
            emitEvent(
              localEventData.newEvent ? 'deleteEvent' : 'deleteSavedEvent'
            )
          "
        >
          <rb-icon
            class="rb-icon--medium u-color-grey-lighter u-spacing-pb-xl"
            :style="{ 'padding-top': currentIndex == 0 ? '42px' : '12px' }"
            icon="cross"
          />
        </div>

        <div
          v-if="localEventData.isSaved"
          class="u-cursor-pointer"
          @click="emitEvent('deleteSavedEvent')"
        >
          <rb-icon
            class="rb-icon--x-medium u-color-grey-lighter u-spacing-pb-xl"
            :style="{ 'padding-top': currentIndex == 0 ? '38px' : '8px' }"
            icon="trash"
          />
        </div>
      </div>
    </div>
    <div class="u-display-flex u-width-100">
      <div
        v-show="error"
        class="u-color-red-base u-font-size-5"
      >
        <rb-icon
          class="u-color-red-base rb-icon--small"
          icon="info-circle-fill"
          data-cy="errorMessage"
        />
        {{ error }}
      </div>
      <div
        v-show="getValidationErrors"
        class="u-color-red-base u-font-size-5 u-margin-left-auto"
      >
        <rb-icon
          class="u-color-red-base rb-icon--small"
          icon="info-circle-fill"
          data-cy="errorMessage"
        />
        {{ getValidationErrors }}
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, debounce } from 'lodash';
import Datepicker from 'vuejs-datepicker';
import customToolTip from '@/components/widgets/customToolTip.vue';
import moment from 'moment-timezone';
import {
  dateRangeOutsideOneMonth,
  endDateLessThanStartDate
} from '@/pages/budget-planner-v2/create-plan/steps/seasonalityStep/utils.js';
export default {
  components: {
    Datepicker,
    customToolTip
  },
  props: {
    remainingBudget: {
      default: null,
      type: Object
    },
    showHeader: {
      default: false,
      type: Boolean
    },
    config: {
      default: () => {},
      type: Object
    },
    scopeItems: {
      default: () => [],
      type: Array
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    validationErrors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      error: '',
      delimiter: '|-|',
      childScopeKey: 0,
      localEventData: {},
      selectedParentItem: {},
      selectedChildItems: [],
      selectedChildOptions: [],
      dataPreLoaded: false
    };
  },
  computed: {
    computedSelectedChildOptions() {
      return this.selectedChildOptions.map((item) => {
        const parent = item?.value?.split(this.delimiter)?.[1];
        if (parent) {
          const title = item.value.split(this.delimiter)[0];
          item.title = `${title === 'null' ? 'Rest' : title} (${parent})`;
        }
        return item;
      });
    },
    getActualSeasonalityEntityMap() {
      return this.$store.getters['budgetPlannerOmni/getSeasonalityEntityMap'];
    },
    flatSeasonalityMap() {
      return Object.values(this.getActualSeasonalityEntityMap || {}).flat();
    },
    getSeasonalityEntityMap() {
      const map = this.getActualSeasonalityEntityMap;
      return Object.keys(map).reduce((acc, key) => {
        const newKey = key.split('--|--')[0];
        acc.set(newKey, [...(acc.get(newKey) || []), ...map[key]]);
        return acc;
      }, new Map());
    },

    entityBudget() {
      const currentMonth = this.getCurrentMonth();
      let budget = 0;
      if (this.selectedChildItems.length) {
        for (const row of this.selectedChildItems) {
          for (const executionStrategy of row.executionStrategy) {
            if (executionStrategy.startDate === currentMonth) {
              budget += parseInt(executionStrategy?.budget);
            }
          }
        }
      } else {
        this.selectedParentItem?.executionStrategy?.forEach((item) => {
          if (item.startDate === currentMonth) {
            budget += parseInt(item?.budget);
          }
        });
      }

      let budgetText = `Of ${moment(currentMonth).format(
        'MMMM'
      )}'s budget plan of ${this.$currency}${parseFloat(
        budget
      )?.toLocaleString()}`;
      if (isNaN(budget)) {
        budgetText = 'Could not calculate left budget';
      }
      return { text: budgetText, budget };
    },

    eventBudgetInfo() {
      const currentMonth = this.getCurrentMonth();
      const { message, budgetInvalid, remaining, remaningBudgetForMonth } =
        this.calculateRemainingBudget(currentMonth);
      let computedRemaining = remaining;
      let show = true;
      if (this.localEventData.isSaved) {
        computedRemaining = this.computedRemainingBudget(currentMonth);
      } else if (!this.localEventData.newEvent) {
        show = false;
      }

      const remainingBudgetPercentage =
        (computedRemaining / this.entityBudget?.budget) * 100;
      return {
        currentMonth,
        remaningBudgetForMonth,
        message,
        budgetInvalid,
        remaining: parseFloat(computedRemaining)?.toLocaleString(),
        remainingBudgetPercentage: remainingBudgetPercentage?.toFixed(2),
        show
      };
    },
    getValidationErrors() {
      let text = '';
      if (this.validationErrors.length) {
        let currentMonth = this.getCurrentMonth();
        text = this.validationErrors
          .filter((val) => {
            let scope = this.localEventData.scope?.[0]?.includes(this.delimiter)
              ? this.splitAndReverse(this.localEventData.scope?.[0] || '')
              : this.localEventData.scope?.[0];
            return (
              val.startDate === currentMonth && val.qualifiedName === scope
            );
          })
          .map((val) => val.message)
          .join('. ');
      }
      return text;
    },
    scopeDropDownDisplayText() {
      let displayText = 'Select Scope';
      if (this.selectedParentItem.title) {
        displayText = this.selectedParentItem.title;
      }
      if (this.selectedChildItems.length) {
        displayText += ' > ';
        this.selectedChildItems.forEach((item, index) => {
          displayText += item.title;
          if (index !== this.selectedChildItems.length - 1) {
            displayText += ', ';
          }
        });
      }
      return displayText;
    },
    disabledDates() {
      const nextYearPlan = this.$route.query.nextYearPlan;
      const from = this.$route.query.nextYearPlan
        ? new Date(new Date().getFullYear() + 1, 11, 31)
        : new Date(new Date().getFullYear() + 1, 0, 1);
      const to = nextYearPlan
        ? new Date(new Date().getFullYear() + 1, 0, 1)
        : new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          );
      return {
        from,
        to
      };
    },
    dateRangeOutsideOneMonth() {
      // making sure date range is in same month
      return dateRangeOutsideOneMonth(
        this.localEventData.startDate,
        this.localEventData.endDate
      );
    },
    endDateLessThanStartDate() {
      return endDateLessThanStartDate(
        this.localEventData.startDate,
        this.localEventData.endDate
      );
    },
    dateErrorMsgs() {
      const dateErrorMsgs = [];
      if (this.dateRangeOutsideOneMonth) {
        dateErrorMsgs.push('Date range is not within a month');
      }
      if (this.endDateLessThanStartDate) {
        dateErrorMsgs.push('End date is less than start date');
      }
      return dateErrorMsgs;
    }
  },
  watch: {
    selectedParentItem(newVal, oldVal) {
      if (!isEmpty(oldVal)) {
        this.updateEvent();
      }
    },
    selectedChildItems(newVal, oldVal) {
      if (!isEmpty(oldVal)) {
        this.updateEvent();
      }
    },
    'localEventData.endDate': {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.updateEvent();
        }
      },
      deep: true
    },
    'localEventData.startDate': {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.updateEvent();
        }
      },
      deep: true
    },
    'localEventData.scope': {
      handler(newVal, oldVal) {
        if (!isEmpty(oldVal)) {
          this.updateEvent();
        }
      },
      deep: true
    },
    config: {
      handler(newVal) {
        this.localEventData = newVal;
      },
      deep: true
    }
  },
  created() {
    this.localEventData = cloneDeep(this.config);
    this.setScopeData();
    this.updateParentAndChildScope();
  },
  methods: {
    computedRemainingBudget(currentMonth) {
      let budget = 0;
      for (let row of this.selectedChildItems) {
        let value = row.value;
        if (value.split(this.delimiter).length > 1) {
          value = this.splitAndReverse(value);
        }
        budget =
          budget +
          (this.getBudgetForMonth(
            this.remainingBudget?.[currentMonth] || {},
            value
          ) || 0);
      }
      return budget;
    },
    doesSeasonalityOverlap(
      startDate,
      endDate,
      existingEventStartDate,
      existingEventEndDate,
      id,
      existingEventId
    ) {
      return (
        this.doesDateRangeOveralap(
          startDate,
          endDate,
          existingEventStartDate,
          existingEventEndDate
        ) && id !== existingEventId
      );
    },
    validateSeasonality() {
      const { scope, endDate, startDate, id } = this.localEventData;
      let doesOverlap = false;
      // if EB event exists and check if new event coincides with EB event
      // if same scope(level1<=>level1, level2<=>level2, EB<=>EB) seasonality event exists, check if they overlap
      if (this.getSeasonalityEntityMap?.get(scope[0])?.length) {
        const events = this.getSeasonalityEntityMap?.get(scope[0]) || [];
        for (let event of events) {
          doesOverlap = this.doesSeasonalityOverlap(
            startDate,
            endDate,
            event.startDate,
            event.endDate,
            id,
            event.id
          );
        }
      } else if (scope[0].includes(this.delimiter)) {
        // if new event is level2, check if overlaps with existing level1 seasonality event
        const level1 = scope[0].split(this.delimiter)[1];
        for (let level of [level1, scope[0]]) {
          const events = this.getSeasonalityEntityMap?.get(level) || [];
          for (let event of events) {
            doesOverlap = this.doesSeasonalityOverlap(
              startDate,
              endDate,
              event.startDate,
              event.endDate,
              id,
              event.id
            );
          }
        }
      } else {
        doesOverlap = this.checkLevel1Level2Overlap(
          doesOverlap,
          scope,
          startDate,
          endDate,
          id
        );
      }
      return !doesOverlap;
    },
    checkLevel1Level2Overlap(doesOverlap, scope, startDate, endDate, id) {
      // if new event is level1, check if overlaps with existing level2 seasonality event
      const level2EventKeys = [...this.getSeasonalityEntityMap.keys()].filter(
        (val) => val.includes[this.delimiter]
      );
      for (let key of level2EventKeys) {
        const level1 = key.split(this.delimiter)[1];
        if (level1 === scope[0]) {
          // here scope[0] is level1
          const events = this.getSeasonalityEntityMap.get(key) || [];
          for (let event of events) {
            doesOverlap = this.doesSeasonalityOverlap(
              startDate,
              endDate,
              event.startDate,
              event.endDate,
              id,
              event.id
            );
          }
        }
      }
      return doesOverlap;
    },
    doesDateRangeOveralap(
      currentStartDate,
      currentEndDate,
      startDate,
      endDate
    ) {
      const rangeI = moment.range(startDate, endDate);
      const rangeJ = moment.range(
        moment(currentStartDate).format('YYYY-MM-DD'),
        moment(currentEndDate).format('YYYY-MM-DD')
      );
      return rangeI.overlaps(rangeJ, { adjacent: true });
    },
    budgetChanged() {
      if (
        this.localEventData.budgetType === 'percentage' &&
        this.localEventData.budget > 100
      ) {
        this.localEventData.budget = 100;
      }
      this.updateEvent();
    },
    eventLabelChanged() {
      this.updateEvent();
    },
    hideStartCalendar() {
      this.$refs.startDate.close();
    },
    hideEndCalendar() {
      this.$refs.endDate.close();
    },
    setScopeData() {
      if (this.localEventData.isSaved) {
        const { selectedParentItem, selectedChildItems } = this.localEventData;
        this.selectedParentItem = selectedParentItem;
        this.selectedChildItems = selectedChildItems.map((item) => {
          item.selected = true;
          return item;
        });
        this.updateParentAndChildScope(false);
        this.childScopeKey++;
      }
    },
    saveEvent() {
      this.emitEvent('saveEvent');
    },
    findBudgetValue(budget) {
      const type = this.localEventData.budgetType;
      const value = this?.localEventData?.budget || 0;
      if (type === 'percentage') {
        return (budget * value) / 100;
      } else {
        return value;
      }
    },
    calculateRemainingBudget(currentMonth) {
      let budget = 0;
      let remaining = 0;
      let message = '';
      let budgetInvalid = false;
      let remaningBudgetForMonth = 0;
      if (this.selectedChildItems.length) {
        for (const row of this.selectedChildItems) {
          let value = row.value;
          if (value.split(this.delimiter).length > 1) {
            value = this.splitAndReverse(value);
          }
          remaningBudgetForMonth = this.getBudgetForMonth(
            this.remainingBudget?.[currentMonth] || {},
            value
          );
          budget += remaningBudgetForMonth;
          remaining = budget - this.findBudgetValue(budget);
          if (remaining < 0) {
            budgetInvalid = true;
            message = `Over budget for ${row.title}`;
          }
        }
        remaining = budget - this.findBudgetValue(budget);
      } else {
        budget = this.getBudgetForMonth(
          this.remainingBudget?.[currentMonth] || {},
          this.selectedParentItem?.value
        );
        remaningBudgetForMonth = budget;
        remaining = budget - this.findBudgetValue(budget);
      }
      if (remaining < 0) {
        budgetInvalid = true;
      }
      return {
        remaining,
        remaningBudgetForMonth,
        message,
        budgetInvalid
      };
    },
    splitAndReverse(value) {
      const splitValue = value.split(this.delimiter);
      let level2Name = splitValue?.[1];
      if (level2Name === 'null') {
        level2Name = 'Rest';
      }
      return `${level2Name}${this.delimiter}${splitValue[0]}`;
    },
    getCurrentMonth() {
      return moment(this.localEventData.startDate)
        .startOf('month')
        .format('YYYY-MM-DD');
    },
    getBudgetForMonth(monthBudget, entity) {
      let finalEntity = entity;
      const splitEntity = entity?.split(this.delimiter);
      if (splitEntity?.[1] === 'null') {
        finalEntity = `${splitEntity[0]}${this.delimiter}Rest`;
      }
      return monthBudget?.[finalEntity];
    },
    onCloseParentScope(a, data) {
      if (!data.length) {
        return;
      }
      const isSelectedParentSame =
        data?.[0]?.title === this.selectedParentItem?.title;
      this.selectedParentItem = data[0];
      if (data[0]?.nextLevel?.length) {
        if (!isSelectedParentSame) {
          this.selectedChildOptions = cloneDeep(data[0].nextLevel);
        }
        this.$refs?.[this.localEventData.id + '-scope']?.click();
      } else {
        this.selectedChildItems = [];
      }
      this.updateParentAndChildScope();
      this.updateEvent();
    },
    onCloseChildScope(content, data) {
      if (!data?.length && !this.selectedChildItems.length) {
        this.selectedChildItems = [];
        this.selectedParentItem = {};
      } else if (data?.length) {
        this.selectedChildItems = data;
      }
      this.updateParentAndChildScope();
      this.updateEvent();
    },
    updateSelectedParent() {
      for (let parentItem of this.selectedParentItem?.nextLevel || []) {
        for (let childItem of this.selectedChildItems) {
          if (parentItem.value === childItem.value) {
            parentItem.selected = true;
          }
        }
      }
    },
    updateParentAndChildScope(updateParentItems = true) {
      if (updateParentItems) {
        this.localEventData.selectedParentItem = this.selectedParentItem;
        this.localEventData.selectedChildItems = this.selectedChildItems;
        this.updateSelectedParent();
      }

      if (this.selectedChildItems.length) {
        this.localEventData.scope = this.selectedChildItems.map(
          (item) => item.value
        );
      } else if (this.selectedParentItem?.value === 'EB') {
        this.localEventData.scope = [this.selectedParentItem.value];
      }
      this.selectedChildOptions = this.selectedParentItem?.nextLevel || [];
    },
    emitEvent(name) {
      this.$emit(name, this.localEventData);
    },
    budgetTypeChanged(budgetType) {
      this.localEventData.budget = null;
      if (budgetType === 'percentage') {
        this.localEventData.budget = Math.min(
          100,
          this.localEventData.budget || 0
        );
        this.updateEvent();
      }
      this.localEventData.budgetType = budgetType;
    },
    openCalendar(param) {
      this?.$refs?.[param]?.showCalendar();
    },
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM DD, YYYY');
    },
    isNameValid(name) {
      const result = !this.flatSeasonalityMap.some(
        (val) =>
          val?.id !== this.localEventData?.id &&
          val?.eventName?.trim() === this.localEventData?.name?.trim()
      );
      if (!result)
        this.error = 'Seasonality event name overlaps with existing event';
      return name && name?.length > 0 && result;
    },
    updateEvent: debounce(function () {
      this.error = '';
      const { startDate, endDate, name, budget, scope } = this.localEventData;
      if (!scope?.length) return;
      const isDateValid =
        !dateRangeOutsideOneMonth(startDate, endDate) &&
        !endDateLessThanStartDate(startDate, endDate);

      const isNameValid = this.isNameValid(name);
      const isBudgetValid =
        ![null, undefined, ''].includes(budget) && budget >= 0;
      const isGenerallyEventValid = isNameValid && isBudgetValid && isDateValid;
      if (isGenerallyEventValid) {
        if (this.validateSeasonality()) {
          this.localEventData.isValid = true;
        } else {
          console.log('invalid seasonality event');
          this.localEventData.isValid = false;
          this.error = 'Seasonality event overlaps with existing event';
        }
      } else {
        console.log('invalid event');
        this.localEventData.isValid = false;
      }
      this.emitEvent('updateEvent');
    }, 500)
  }
};
</script>

<style lang="css">
.tree-select:hover {
  background-color: #007cf6;
}
</style>
